import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import GLOBE from "vanta/dist/vanta.globe.min";
import HTMLFlipBook from "react-pageflip";
import image1 from "./assets/earth-3537401_1280.jpg";
import missionImg from "./assets/ian-schneider-TamMbr4okv4-unsplash.jpg";
import visionImg from "./assets/fractal-1746832_1280.jpg";
import ourStoryImg from "./assets/moon-6374498_1280.jpg";
import teanImg from "./assets/puzzle-7507357_1280.jpg";
import approachImg from "./assets/finance-8836903_1280.jpg";
import whyUseImag from "./assets/question-mark-1872665_1280.jpg";
import "./aboutUs.css";
import { Link } from "react-router-dom";

// PageCover Component
const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="page page-cover" ref={ref} data-density="hard">
      <div className="page-content flex flex-col items-center justify-center h-full">
        {props.children}
      </div>
    </div>
  );
});

// Page Component
const Page = React.forwardRef((props, ref) => {
  const contentRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const SWIPE_THRESHOLD = 50; // pixels
  const DELAY_THRESHOLD = 1000; // milliseconds

  useEffect(() => {
    if (contentRef.current) {
      setIsScrollable(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [props.children]);

  const handleTouchStart = (e) => {
    setTouchStartTime(Date.now());
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    e.stopPropagation(); // Always stop propagation to prevent unwanted page turns
    
    if (!isScrollable) return;

    const touchEndY = e.touches[0].clientY;
    const touchEndTime = Date.now();
    const yDiff = touchStartY - touchEndY;
    const timeDiff = touchEndTime - touchStartTime;

    if (timeDiff < DELAY_THRESHOLD) {
      // If the touch duration is less than the threshold, it's likely a swipe
      // Don't scroll, but allow the event to potentially turn the page
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

    // Scroll the content if needed
    if ((yDiff > 0 && scrollTop < scrollHeight - clientHeight) || 
        (yDiff < 0 && scrollTop > 0)) {
      contentRef.current.scrollTop += yDiff;
    }

    // If it's a significant swipe, allow the event to bubble up for page turning
    if (Math.abs(yDiff) > SWIPE_THRESHOLD) {
      e.stopPropagation();
    }
  };

  return (
    <div className="page" ref={ref}>
      <div 
        className="page-content overflow-y-auto"
        ref={contentRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{ height: '100%', overscrollBehavior: 'contain' }}
      >
        <h2 className="page-header text-xl font-bold text-blue-800 mb-4">
          {props.header}
        </h2>
        <img
          src={props.imageSrc}
          alt={`Page ${props.number}`}
          className="page-img"
        />
        <p className="page-text text-gray-700 text-base leading-relaxed">
          {props.children}
        </p>
        <div className="page-footer">{props.number + 1}</div>
      </div>
    </div>
  );
});

const AboutUs = () => {
  const vantaRef = useRef(null);
  const flipbookRef = useRef(null);

  // Initialize Vanta Globe
  useEffect(() => {
    const vantaEffect = GLOBE({
      el: vantaRef.current,
      THREE,
      color: 0x0d47a1,
      backgroundColor: 0x000000,
      scale: 1.4,
      scaleMobile: 1.0,
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <section
      className="vanta-bg-container"
      ref={vantaRef}
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        color: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <HTMLFlipBook
        width={350}
        height={500}
        size="fixed"
        minWidth={250}
        maxWidth={600}
        minHeight={300}
        maxHeight={800}
        maxShadowOpacity={0.05}
        flippingTime={2000}
        showCover={true}
        mobileScrollSupport={true}
        className="demo-book"
        ref={flipbookRef}
      >
        <PageCover>Welcome to KSquant <br/> <span className="text-center text-white mt-10">Click or swipe to view</span></PageCover>
       
        <Page number={1} imageSrc={image1} header="About Us">
          Welcome to KSquantitative, A premier hub for mastering
          quantitative finance and algorithmic trading. We blend professional
          expertise with cutting-edge technology to deliver transformative
          educational experiences and robust trading solutions. Whether you're
          an investor seeking consistent returns, a trader looking to sharpen
          your skills, or a financial institution aiming to stay ahead of market
          trends, KSMquantitative is here to support your financial success.
        </Page>
        <Page number={2} imageSrc={missionImg} header="Our Mission">
          At KSMquantitative, our mission is to empower investors, traders, and
          financial institutions with the knowledge, tools, and strategies
          needed to excel in the world of quantitative finance and algorithmic
          trading. We are dedicated to fostering a community of professionals
          who leverage data-driven approaches to achieve consistent, superior
          financial outcomes.
        </Page>
        <Page number={3} header="Our Vision" imageSrc={visionImg}>
          We envision a future where KSMquantitative is recognized as the
          leading authority in quantitative finance and algorithmic trading
          across Africa and beyond. Our goal is to create a hub of excellence
          where innovation, research, and education converge to shape the next
          generation of financial experts.
        </Page>
        <Page number={4} header="Our Story" imageSrc={ourStoryImg}>
          Founded in Lagos, Nigeria, KSMquantitative was born out of a passion
          for transforming the financial landscape through quantitative methods.
          Recognizing the challenges faced by traders and investors in
          navigating complex markets, we set out to create a platform that
          bridges the gap between traditional finance and cutting-edge
          technology. With a commitment to professionalism and innovation, we’ve
          grown into a trusted partner for those seeking to enhance their
          financial acumen and achieve consistent trading success.
        </Page>
        <Page number={5} header="Our Team" imageSrc={teanImg}>
          Our team comprises seasoned experts in quantitative finance,
          algorithmic trading, and financial education. With years of experience
          in both the academic and practical aspects of finance, our
          professionals are dedicated to providing top-tier training and
          developing sophisticated trading algorithms. We are a collective of
          researchers, traders, and educators who are passionate about driving
          progress in the financial industry.
        </Page>
        <Page number={6} header="Our Approach" imageSrc={approachImg}>
          At KSMquantitative, we believe in the power of data-driven
          decision-making. Our approach integrates advanced scientific
          techniques with practical trading strategies, ensuring that our
          clients and students are equipped with the best tools to succeed in
          today’s dynamic markets. We focus on excellence, innovation, and
          continuous learning, setting our clients up for long-term success.
        </Page>
        <Page number={7} header="Why Choose Us?" imageSrc={whyUseImag}>
          {" "}
          <h3>Expertise</h3> Our team is composed of professionals with deep
          knowledge and experience in quantitative finance and algorithmic
          trading.
          <h3>Innovation</h3> We stay ahead of market trends by constantly
          refining our strategies and tools to deliver cutting-edge solutions. -
          <h3>Community</h3> We are more than just a service provider; we are a
          community committed to helping each other succeed.
        </Page>
        {/* ... (other pages remain the same) ... */}
        <PageCover>
          <div className="flex flex-col items-center justify-center h-full">
            <h2 className="text-2xl font-bold mb-4">The End</h2>
            <Link
              to="/service"
              className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-700 transition-colors duration-300"
            >
              Check Our Services
            </Link>
          </div>
        </PageCover>
      </HTMLFlipBook>
    </section>
  );
};

export default AboutUs;