import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import GLOBE from "vanta/dist/vanta.globe.min";
import { ReactTyped } from "react-typed";
import { Link } from "react-router-dom";

const Home = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;

    if (vantaRef.current) {
      vantaEffect = GLOBE({
        el: vantaRef.current,
        THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        scale: 1.4,
        scaleMobile: 1.0,
        color: 0x0d47a1,
        backgroundColor: 0x000000,
      });
    }

    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, []);

  return (
    <section
      ref={vantaRef}
      className="relative h-screen w-full flex justify-center items-center overflow-hidden text-white"
    >
      <div className="text-center z-10 max-w-screen-md mx-auto px-4 md:px-8 bg-[rgba(255,255,255,0.10)] backdrop-blur-md p-6 rounded-lg shadow-[0_7px_29px_rgba(100,100,111,0.2)]">
        <h1 className="text-2xl md:text-3xl font-bold mb-6">
          Innovative Quantitative Trading Solutions and Education Tailored for{" "}
          <br />
          <ReactTyped
            className="text-indigo-700"
            strings={[
              "Investors.",
              "Crypto, Stocks, Forex Traders.",
              "Software Engineers.",
              "Data Scientists.",
              "Students.",
              "Financial institutions.",
              "Hedge funds.",
            ]}
            typeSpeed={150}
            backSpeed={50}
            loop
          />
        </h1>
        <p className="text-base md:text-xl mb-8">
          Your Gateway to Quantitative Finance and Algorithmic Trading Excellence
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a
            href="https://calendly.com/adiec7/consultation"
            className="px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base lg:text-lg font-semibold text-white bg-indigo-700 rounded-lg hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 transition-colors duration-200 shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)]"
            target="_blank"
            rel="noreferrer"
          >
            Book a Consultation
          </a>
          <Link
            to="/about"
            className="px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base lg:text-lg font-semibold text-indigo-700 bg-white border-2 border-indigo-700 rounded-lg hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 transition-colors duration-200 shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)]"
          >
            About Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
