import React from 'react';
import { PhoneIcon, CalendarIcon, MapPinIcon, MailIcon } from 'lucide-react';

const ContactUs = () => {
  return (
    <div className="vanta-bg-container min-h-screen flex items-center justify-center bg-gray-900 text-white p-4">
      <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-8 max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-400">Contact Us</h2>
        
        <div className="space-y-6">
          {/* WhatsApp Chat Button */}
          <a 
            href="https://wa.me/+2347026976893" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            <PhoneIcon className="mr-2" size={20} />
            Chat with Us on WhatsApp
          </a>
          
          {/* Book Appointment Button */}
          <a 
            href="https://calendly.com/adiec7/consultation" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            <CalendarIcon className="mr-2" size={20} />
            Book an Appointment
          </a>
          
          {/* Address */}
          <div className="flex items-start mt-6 bg-gray-800 bg-opacity-50 p-4 rounded-lg">
            <MapPinIcon className="mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-sm">
              TPDC Off Lekki Conservation Road, Lagos, Nigeria
            </p>
          </div>
          
          {/* Email */}
          <div className="flex items-start mt-4 bg-gray-800 bg-opacity-50 p-4 rounded-lg">
            <MailIcon className="mr-2 mt-1 flex-shrink-0" size={20} />
            <a 
              href="mailto:hello@ksquants.com" 
              className="text-sm text-blue-400 hover:underline"
            >
              hello@ksquants.com
            </a>
          </div>
        </div>
        
        <p className="mt-8 text-center text-sm text-gray-300">
          We're excited to connect with you and explore how our quantitative strategies and algorithmic solutions can help you achieve your financial objectives.
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
